@use '../common' as *;

.c-color-base{
	color:$color-base !important;
}
.c-color-beige{
	color:$color-beige !important;
}
.c-color-purple{
	color:$color-purple !important;
}
.c-color-red{
	color:$color-red !important;
}
.c-color-darkpink{
	color:$color-darkpink !important;
}
.c-color-pink{
	color:$color-pink !important;
}
.c-color-orange{
	color:$color-orange !important;
}
.c-color-lime{
	color:$color-lime !important;
}
.c-color-green{
	color:$color-green !important;
}
.c-color-sky{
	color:$color-sky !important;
}
.c-color-blue{
	color:$color-blue !important;
}

.c-bottom-color-base{
	border-bottom:1px solid $color-base !important;
}
.c-bottom-color-beige{
	border-bottom:1px solid $color-beige !important;
}
.c-bottom-color-purple{
	border-bottom:1px solid $color-purple !important;
}
.c-bottom-color-red{
	border-bottom:1px solid $color-red !important;
}
.c-bottom-color-darkpink{
	border-bottom:1px solid $color-darkpink !important;
}
.c-bottom-color-pink{
	border-bottom:1px solid $color-pink !important;
}
.c-bottom-color-orange{
	border-bottom:1px solid $color-orange !important;
}
.c-bottom-color-lime{
	border-bottom:1px solid $color-lime !important;
}
.c-bottom-color-green{
	border-bottom:1px solid $color-green !important;
}
.c-bottom-color-sky{
	border-bottom:1px solid $color-sky !important;
}
.c-bottom-color-blue{
	border-bottom:1px solid $color-blue !important;
}



.c-edge{
	position: relative;
	max-width: 488px;
	width: 100%;
	aspect-ratio: 488 / 320;
	margin: 0 auto;
	&:before,&:after{
		content: "";
		position: absolute;
		width: 85px;
		height: 1px;
		background-color: black;
		z-index: 1;
		transform: rotate(-45deg);
		@include SP{
			width: 60px;
		}
	}
	&:before{
		  top: 19px;
		  left: -24px;
		  @include SP{
			left:-11px;
		  }
	}
	&:after{
		  bottom: 19px;
		  right: -24px;
		  @include SP{
			right:-11px;
		  }
	}
	figure,img,div{
		clip-path: polygon(
			38px 0%,
			100% 0%,
			100% calc(100% - 38px),
			calc(100% - 38px) 100%,
			0% 100%,
			0% 38px
		);
	}
}
.c-edge-mini{
	position: relative;
	width: 100%;
	max-width: 200px;
	aspect-ratio: 200 / 129;
	@include SP{
		margin:0 auto;
	}
	&:before,&:after{
		content: "";
		position: absolute;
		width: 40px;
		height: 1px;
		background-color: black;
		z-index: 1;
		transform: rotate(-45deg);
	}
	&:before{
		  top: 10px;
		  left: -10px;
	}
	&:after{
		  bottom: 10px;
		  right: -10px;
	}
	figure,img,div{
		clip-path: polygon(
			20px 0%,
			100% 0%,
			100% calc(100% - 20px),
			calc(100% - 20px) 100%,
			0% 100%,
			0% 20px
		);
	}
}

.c-rainbow{
	border: none;
	height: 3px;
	background: linear-gradient(
	  to right,
	  $color-orange,
	  $color-lime,
	  $color-green,
	  $color-sky,
	  $color-blue,
	  $color-purple,
	  $color-red,
	  $color-darkpink,
	  $color-pink
	);
	border-radius: 999px; /* 丸みでなめらかに */
	margin: 2em 0;
	@include SP{
		height: 2px;
	}
}


//記事一覧
.c-article-list{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	position: relative;
	gap:58px 8.75%;
	width: 100%;
	@include SP{
		gap:40px 0;
		flex-direction: column;
	}
	.item{
		width: 45.437%;
		position: relative;
		@include SP{
			width: 100%;
		}
		.upper{
			.pic{
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
			.info{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				position: relative;
				margin-top: 33px;
				@include SP{
					margin-top: 18px;
				}
				&:before{
					content:'';
					width: 44px;
					height: 1px;
					display: block;
					background-color: $color-base;
					margin-right: 10px;
					@include SP{
						width: 22px;
						margin-right: 8px;
					}
				}
				time{
					font-family: $inter;
					font-style: italic;
					@include font-size(17);
					display: flex;
					align-items: center;
					margin-right: 0.8em;
					padding-top: 5px;
					@include SP{
						@include font-size(13);
						padding-top: 2px;
					}
					&:after{
						content:'';
						width: 1px;
						height: 20px;
						display: block;
						margin-left: 0.8em;
						background-color: $color-base;
						@include SP{
							height:14px;
						}
					}
				}
				.cat{
					@include font-size(17);
					letter-spacing: 3px;
					display: flex;
					align-items: center;
					line-height: 1em;
					@include SP{
						@include font-size(13);
						letter-spacing: 2px;
					}
				}
			}
			.title{
				margin-top: 12px;
				@include font-size(20);
				@include letter-spacing(100);
				line-height: 1.81818em;
				@include SP{
					@include font-size(16);
					margin-top: 7px;
				}
			}
			.person{
				@include font-size(16);
				@include letter-spacing(200);
				line-height: 2.1em;
				@include SP{
					@include font-size(13);
				}
			}
		}
		.tag-list{
			border-top: 1px dashed $color-base;
			margin-top: 15px;
			padding-top: 22px;
			display: flex;
			flex-wrap: wrap;
			gap:1em 0.5em;
			@include SP{
				margin-top: 10px;
				padding-top: 16px;
			}
			.tag{
				@include font-size(15);
				@include letter-spacing(30);
				line-height: 1em;
				@include SP{
					@include font-size(12);
					line-height: 0.6em;
				}
			}
		}
		&:after{
			content:'';
			width: 1px;
			height: 100%;
			display: none;
			position: absolute;
			top:0;
			right:-10%;
			background-color: gray;
		}
		&:nth-child(odd){
			&:after{
				@include PC{
					display: block;
				}
			}
		}
		&:nth-child(1){
			&:after{
				background-color: $color-blue;
			}
		}
		&:nth-child(3){
			&:after{
				background-color: $color-pink;
			}
		}
		&:nth-child(5){
			&:after{
				background-color: $color-green;
			}
		}
		&:nth-child(7){
			&:after{
				background-color: $color-purple;
			}
		}
		&:nth-child(9){
			&:after{
				background-color: $color-orange;
			}
		}
	}
}

.c-color-tag{
	&:before{
		content:"#";
		margin-right: 3px;
		color:gray;
	}
	&.purple{
		&:before{
			color:$color-purple;
		}
	}
	&.red{
		&:before{
			color:$color-red;
		}
	}
	&.darkpink{
		&:before{
			color:$color-darkpink;
		}
	}
	&.pink{
		&:before{
			color:$color-pink;
		}
	}
	&.orange{
		&:before{
			color:$color-orange;
		}
	}
	&.lime{
		&:before{
			color:$color-lime;
		}
	}
	&.green{
		&:before{
			color:$color-green;
		}
	}
	&.sky{
		&:before{
			color:$color-sky;
		}
	}
	&.blue{
		&:before{
			color:$color-blue;
		}
	}
}

//タグから読む
.c-read-from-tag{
	background-color: $color-beige;
	border-radius: 28px;
	padding:50px 53px;
	@include SP{
		width: calc(100% + 50px);
		margin-left: -25px;
		border-radius: 0;
		position: relative;
		padding:24px 24px 24px;
		&:before{
			content:'';
			width: 100%;
			height: 20px;
			background-image: url(../images/common/book_top.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size:100% 20px;
			display: block;
			position: absolute;
			top:-19px;
			left:0;
			margin:auto;
		}
		&:after{
			content:'';
			width: 100%;
			height: 31px;
			background-image: url(../images/common/book_bottom.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size:100% 31px;
			display: block;
			position: absolute;
			bottom:-30px;
			left:0;
			margin:auto;
		}
	}
	&.wh{
		@include PC{
			background-color: white;
		}
	}
	.tag-title{
		@include font-size(24);
		@include letter-spacing(300);
		font-weight: 500;
		line-height: 1em;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap:24px;
		margin-left: -53px;
		@include SP{
			@include font-size(19);
			@include letter-spacing(200);
			margin-left: -24px;
		}
		&:before{
			content:'';
			width: 151px;
			height: 1px;
			display: block;
			background-color: $color-sky;
			@include SP{
				width: 50px;
			}
		}
	}
	.tag-list{
		margin-top: 15px;
		padding-top: 22px;
		display: flex;
		flex-wrap: wrap;
		gap:1em 1.2em;
		@include SP{
			margin-top: 10px;
			padding-top: 16px;
			gap:0.55em 0.8em;
		}
		.tag{
			@include font-size(18);
			line-height: 1.3em;
			@include SP{
				@include font-size(14);
			}
		}
	}
}

// パンくずリスト
.c-breadcrumbs {
	ul {
		display: flex;
		align-items: center;
		gap: 12px;
		@include SP {
			gap: 6px;
		};
		li, a {
			@include font-size(18);
			letter-spacing: 0.22em;
			@include max-screen(1032px) {
				@include font-size(14);
			};
			@include SP {
				@include font-size(10);
				font-feature-settings: "palt";
			};
		}
		li {
			display: flex;
			align-items: center;
			gap: 12px;
			@include SP {
				gap: 6px;
			};
			&:not(:first-child) {
				&:before {
					content: "＞";
					letter-spacing: 0;
				}
			}
		}
	}
}

//SNSボタン
.c-sns{
	display: flex;
	align-items: center;
	justify-content: center;
	gap:10px;
	@include SP{
		gap:6px;
	}
	a{
		display: block;
		width: 52px;
		@include SP{
			width: 34px;
		}
		img{
			width: 100%;
		}
	}
}