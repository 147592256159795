// fonts
// ---------------------------------------------------------------------------
// @font-face {
// 	font-family: 'フォント名';
// 	src: url('フォント名.eot');
// 	src: url('フォント名.eot?iefix') format('eot'),
// 		url('フォント名.woff') format('woff'),
// 		url('フォント名.ttf') format('truetype'),
// 		url('フォント名.svg#webfontjSpbZQRv') format('svg');
// }

// var
// ---------------------------------------------------------------------------
$gothic: 'Noto Sans JP',"游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
$serif: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
$inter: "Inter", sans-serif;
$conforta: 'Comfortaa', cursive;
$color-base: #000000;
$color-beige: #FAF7F1;
$color-purple:#7F1083;
$color-red:#C30D23;
$color-darkpink:#E51373;
$color-pink:#EA6DA4;
$color-orange:#F29600;
$color-lime:#AACC03;
$color-green:#009844;
$color-sky:#00A0E9;
$color-blue:#0068B6;



