@use 'common' as *;
// base
// ---------------------------------------------------------------------------

html {
	color: $color-base;
	font-size: 62.5%;
	font-family: $gothic;
	font-weight:400;
	scroll-behavior: smooth;
}
main{
}
*{
	@include font-size(14);
	box-sizing: border-box;
}
img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}
a,button,.hover{
	@include PC{
		@include hover-opacity;
	}

}
a{
	color: inherit;
	text-decoration: none;
}
button{
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}
.inner {
	width: calc(100% - 60px);
	max-width: 1074px;
	margin: 0 auto;
	@include SP {
		width: calc(100% - 50px);
	}
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
