@use 'common' as *;

.header {
    height: 160px;
    padding:0px 52px;
    width: 100%;
    background-color: white;
    // position: fixed;
    // top:0;
    // left: 0;
    z-index: 100;
    @include SP{
        height: 52px;
        padding:0 16px;
    }
    .inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap:10px;
        max-width: 100%;
        width: 100%;
        height: 100%;
        &:before{
            @include SP{
                content:'';
                width: 54px;
                height: 0px;
            }
        }
        .pen{
            width: 350px;
            @include SP{
                display: none;
            }
        }
        .logo{
            width: 450px;
            @include SP{
                width: 180px;
            }
        }
        .utility{
            width: 350px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap:30px;
            @include SP{
                gap:12px;
                width: 54px;
            }
            .scope{
                width: 36px;
                @include SP{
                    width: 21px;
                }
            }
            .menu{
                width: 35px;
                @include SP{
                    width: 21px;
                }
            }
        }
    }
}

.modal{
    width: 100%;
    height: 100vh;
    background-image: url(../images/common/modal-back.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    top:0;
    left: 0;
    z-index: 1000;
    display: none;
    @include SP{
        background-size: 120% 150%;
    }
    .modal-wrap{
        position: relative;
        .inner{
            max-width: 860px;
            padding:100px 0 80px;
            display: flex;
            align-items: center;
            flex-direction: column;
            @include SP{
                padding:80px 0 80px;
                width:calc(100% - 80px);
            }
            .close{
                position: absolute;
                top:47px;
                right:50px;
                width: 52px;
                @include SP{
                    width: 30px;
                    top:24px;
                    right:24px;
                }
            }
            .menu-logo{
                width: 381px;
                @include SP{
                    width: 250px;
                }
            }
            .only-menu{
                padding-top: 85px;
                @include SP{
                    padding-top: 38px;
                }
                .menu-cont{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap:40px 64px;
                    @include SP{
                        gap:15px;
                    }
                    .item{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap:24px;
                        width: 100%;
                        max-width: 398px;
                        @include SP{
                            gap:16px;
                            max-width: 100%;
                        }
                        .pic{
                            width: 59px;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include SP{
                                width: 40px;
                            }
                            img{
                                &.book{
                                    width: 59px;
                                    @include SP{
                                        width: 40px;
                                    }
                                }
                                &.speaker{
                                    width: 50px;
                                    @include SP{
                                        width: 34px;
                                    }
                                }
                                &.hash{
                                    width: 55px;
                                    @include SP{
                                        width: 37px;
                                    }
                                }
                                &.talk{
                                    width: 49px;
                                    @include SP{
                                        width: 33px;
                                    }
                                }
                            }
                        }
                        .text{
                            color:white;
                            @include font-size(21);
                            @include letter-spacing(200);
                            line-height: 1.5em;
                            border-bottom: 1px solid white;
                            height: 52px;
                            width: calc(100% - 59px - 24px);
                            padding-right: 37px;
                            white-space: nowrap;
                            position: relative;
                            @include SP{
                                @include font-size(14);
                                height: 36px;
                                width: calc(100% - 40px - 16px);
                            }
                            &:after{
                                content:'';
                                width: 37px;
                                height: 13px;
                                background-image: url(../images/common/arr2_wh.svg);
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: 100%;
                                display: block;
                                position: absolute;
                                top:0;
                                bottom: 0;
                                right:0;
                                margin:auto;
                                @include SP{
                                    width: 25px;
                                    height: 9px;
                                    top:7px;
                                    bottom:auto;
                                }
                            }
                        }
                    }
                }
                .info-link-cont{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 76px;
                    @include SP{
                        margin-top: 30px;
                        padding-left: calc(40px + 16px);
                        justify-content: flex-start;
                    }
                    .list{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include SP{
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            max-width: 260px;
                            gap: 18px 0;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        li{
                            @include font-size(17);
                            @include letter-spacing(220);
                            color:white;
                            padding:2px 20px;
                            border-right: 1px solid white;
                            @include SP{
                                @include font-size(12);
                                padding:1px 0;
                                border-right: none;

                            }
                            &:first-child{
                                padding-left: 0;
                                @include SP{
                                    // padding-right: 13px;
                                    // margin-right: 13px;
                                    // border-right: 1px solid white;
                                }
                            }
                            &:last-child{
                                padding-right: 0;
                                border-right: none;
                            }
                            &:nth-child(odd){
                                @include SP{
                                    // padding-right: 13px;
                                    // margin-right: 13px;
                                    // border-right: 1px solid white;
                                }
                            }

                        }
                    }
                }
            }
            .only-search{
                margin-top: 100px;
                width: 100%;
                display: flex;
                justify-content: center;
                @include SP{
                    margin-top: 40px;
                }
                .search-form{
                    position: relative;
                    border: 1px solid white;
                    height: 70px;
                    width: 100%;
                    max-width: 656px;
                    display: flex;
                    align-items: center;
                    padding-left: 70px;
                    @include SP{
                        height: 48px;
                        padding-left: 50px;
                    }
                    &:before{
                        content:'';
                        width: 35px;
                        height: 35px;
                        background-image: url(../images/common/scope_wh.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        display: block;
                        position: absolute;
                        top:0;
                        bottom: 0;
                        left:20px;
                        margin:auto;
                        @include SP{
                            width: 24px;
                            height: 24px;
                            left: 16px;
                        }
                    }
                    input[type="text"]{
                        border: none;
                        position: relative;
                        height: 2.0em;     
                        background-color: rgba(0,0,0,0);
                        width: 100%;
                        height: 100%;  
                        @include font-size(22);
                        color:white;
                        @include letter-spacing(150);
                        caret-color: white;
                        @include SP{
                            @include font-size(14);
                        }
                        &::placeholder{
                            color:white;
                        }
                        &:focus{
                            outline:none;
                        }
                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus {
                            font-size:22px !important;
                            -webkit-text-fill-color: white !important;
                            -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,0) inset;
                            color: white !important;
                            transition: background-color 5000s ease-in-out 0s;
                        }
                    }
                    button{
                        cursor: pointer;
                        border: none;
                        outline : none;
                        width: 70px;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-right: 16px;
                        @include SP{
                            width: 48px;
                            height: 48px;
                        }
                        img{
                            width: 36px;
                        }
                    }
                }
            }
        }
    }
}
