@use '../common' as *;

.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  @include SP {
    gap: 13px;
  };
  span,
  a{
    font-family: $inter;
    @include font-size(14);
    line-height: 1;
    // letter-spacing: 0.05em;
    font-weight: 700;
    transition: 0.3s ease;
  }
  a{
    @include PC{
      &:hover{
        color: rgba($color: #000000, $alpha: 0.5);
        opacity: 1;
      }
    }
  }
  .current{
    color: rgba($color: #000000, $alpha: 0.5);
  }
  .previouspostslink,
  .nextpostslink{
    width: 36px;
    aspect-ratio: 36 / 14;
    background: url(../images/common/arr2.svg) no-repeat center/contain;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .3s ease;
    @include PC{
      &:hover{
        opacity: 1;
      }
    }
    @include SP{
      width: 24px;
    }
  }
  .previouspostslink{
    margin-right: 8px;
    @include PC{
      &:hover{
        transform: rotate(180deg) translateX(10px);
      }
    }
    @include SP {
      margin-right: 5px;
    };
    transform: rotate(180deg);
  }
  .nextpostslink{
    margin-left: 8px;
    @include PC{
      &:hover{
        transform: translateX(10px);
      }
    }
    @include SP {
      margin-left: 5px;
    };
  }
}