@use 'common' as *;

.footer-bottom{
    background-color: $color-beige;
    padding-top:16px;
    padding-bottom: 16px;
    .inner{
        .link-list{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item{
                width: 70px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap:6px;
                .pic{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 36px;
                    img{
                        &.book{
                            width: 36px;
                        }
                        &.speaker{
                            width: 33px;
                        }
                        &.hash{
                            width: 36px;
                        }
                        &.talk{
                            width: 32px;
                        }
                    }
                }
                p{
                    text-align: center;
                    @include font-size(10);
                    line-height: 1.5em;
                }
            }
        }
    }
}