@use 'common' as *;

.footer {
    padding-bottom: 65px;
    @include SP{
        padding-bottom: 30px;
    }
    .inner{
        .logo-cont{
            margin-top: 60px;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap:20px;
            @include SP{
                margin-top: 36px;
                gap:16px;
            }
            .pic{
                width: 100%;
                max-width: 240px;
                @include SP{
                    max-width: 230px;
                }
            }
            .fukidashi{
                width: 68px;
                @include SP{
                    width: 57px;
                }
            }
        }
        .text-cont{
            margin:32px auto 0;
            width: 100%;
            max-width: 800px;
            
            @include SP{
                margin-top: 24px;
                padding: 0 10px;
            }
            p{
                @include font-size(14);
                line-height: 1.6em;
                // @include letter-spacing(100);
                text-align: center;
                @include SP{
                    @include font-size(14);
                    line-height: 1.5em;
                    text-align: justify;
                }
            }
        }
        .sns-cont{
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:22px;
            @include SP{
                margin-top: 30px;
                gap:14px;
            }
            .share{
                width: 174px;
                @include SP{
                    width: 120px;
                }
            }
        }
        .link-cont{
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include font-size(16);
            @include letter-spacing(280);
            line-height: 1em;
            font-weight: 500;
            gap:1em;
            @media screen and (max-width: 1100px) {
                @include font-size(14);
            }
            @media screen and (max-width: 900px) {
                @include font-size(14);
            }
            @include SP{
                display: none;
            }
        }
        .copy{
            @include font-size(12);
            font-family: $inter;
            font-weight: 500;
            text-align: center;
            margin-top: 30px;
            @include SP{
                @include font-size(10);
                margin-top: 24px;
            }
        }
        .info-link-cont{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 33px;
            @include SP{
                margin-top: 22px;
            }
            .list{
                border-top: 1px solid $color-base;
                padding-top: 36px;
                display: flex;
                // align-items: center;
                justify-content: center;
                @include SP{
                    padding-top: 18px;
                    max-width: 325px;
                }
                li{
                    display: flex;
                    align-items: center;
                    @include font-size(14);
                    @include letter-spacing(180);
                    padding:2px 16px;
                    border-right: 1px solid $color-base;
                    @include SP{
                        @include font-size(11);
                        @include letter-spacing(0);
                        padding:1px 10px;
                        text-align: center;
                    }
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                        border-right: none;
                    }
                }
            }
        }
    }
}
