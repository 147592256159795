@use 'common' as *;

#pickup{
    margin-bottom: 80px;
    @include SP{
        margin-bottom: 44px;
    }
    .inner{
        .pickup-title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap:20px;
            max-width: 800px;
            margin: 0 auto;
            @include SP{
                flex-direction: column;
                justify-content: center;
                gap:12px;
            }
            &:before{
                @include PC{
                    content:'';
                    width: 33.333%;
                    height: 1px;
                    display: block;
                    background-color: #C277AD;
                }
            }
            .pic{
                width: 100%;
                max-width: 160px;
                padding-top: 30px;
                @media screen and (max-width: 1000px) {
                    max-width: 180px;
                }
                @include SP{
                    width: 120px;
                    padding-top: 10px;
                }
            }
            .words{
                @include font-size(20);
                @include letter-spacing(300);
                font-weight: 500;
                line-height: 1.75em;
                width: 33.333%;
                white-space: nowrap;
                @media screen and (max-width: 1000px) {
                    @include font-size(24);
                }
                @include SP{
                    @include font-size(18);
                    @include letter-spacing(300);
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .pickup-list{
            display: flex;
            flex-direction: column;
            margin-top: 54px;
            @include PC{
                width: 100%;
                max-width: 800px;
                margin-right: auto;
                margin-left: auto;
            }
            @include SP{
                margin-top: 24px;
            }
            .pickup-item{
                width: 100%;
                padding-bottom: 28px;
                border-bottom: 1px solid gray;
                margin-bottom: 28px;
                @include SP{
                    padding-bottom: 22px;
                    margin-bottom: 14px;
                }
                &:nth-child(1){
                    border-bottom: 1px solid $color-lime;
                }
                &:nth-child(2){
                    border-bottom: 1px solid $color-purple;
                }
                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
                a{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: flex-start;
                    gap:0 42px;
                    position: relative;
                    @include SP{
                        padding-bottom: 16px;
                    }
                    &:after{
                        content:'';
                        width: 46px;
                        height: 10px;
                        background-image: url(../images/common/arr1.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        display: block;
                        position: absolute;
                        bottom: 0;
                        right:0;
                        margin:auto;
                        @include SP{
                            width: 30px;
                            height: 7px;
                        }
                    }
                    .pic-cont{
                        @include SP{
                            display: none;
                        }
                        .pic{
                            transition: .3s;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text{
                        @include font-size(20);
                        @include letter-spacing(180);
                        line-height: 1.8em;
                        padding-right: 50px;
                        @include SP{
                            @include font-size(16);
                            @include letter-spacing(100);
                            line-height: 1.7em;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}