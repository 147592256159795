@charset "UTF-8";
@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

/* argument */
/* site style */
/* [ opacity ]
-------------------------------------------------*/
/* [ display style ]
-------------------------------------------------*/
/* [ background-image ]
-------------------------------------------------*/
/* [ writing-mode ]
-------------------------------------------------*/
/* [ illustrator & photoshop letter spacing ]
-------------------------------------------------*/
/* [ easy breakpoint ]
-------------------------------------------------*/
/* [ easy transform ]
-------------------------------------------------*/
/* [ writing-mode ]
-------------------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  color: #000000;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
}

* {
  font-size: 14px;
  font-size: 1.4rem;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

@media only screen and (min-width: 768px) {
  a, button, .hover {
    cursor: pointer;
    transition: all 0.3s;
  }
  a:hover, button:hover, .hover:hover {
    opacity: 0.7;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

.inner {
  width: calc(100% - 60px);
  max-width: 1074px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .inner {
    width: calc(100% - 50px);
  }
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-bottom {
  background-color: #FAF7F1;
  padding-top: 16px;
  padding-bottom: 16px;
}
.footer-bottom .inner .link-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-bottom .inner .link-list .item {
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}
.footer-bottom .inner .link-list .item .pic {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
}
.footer-bottom .inner .link-list .item .pic img.book {
  width: 36px;
}
.footer-bottom .inner .link-list .item .pic img.speaker {
  width: 33px;
}
.footer-bottom .inner .link-list .item .pic img.hash {
  width: 36px;
}
.footer-bottom .inner .link-list .item .pic img.talk {
  width: 32px;
}
.footer-bottom .inner .link-list .item p {
  text-align: center;
  font-size: 10px;
  font-size: 1rem;
  line-height: 1.5em;
}

.footer {
  padding-bottom: 65px;
}
@media only screen and (max-width: 767px) {
  .footer {
    padding-bottom: 30px;
  }
}
.footer .inner .logo-cont {
  margin-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
@media only screen and (max-width: 767px) {
  .footer .inner .logo-cont {
    margin-top: 36px;
    gap: 16px;
  }
}
.footer .inner .logo-cont .pic {
  width: 100%;
  max-width: 240px;
}
@media only screen and (max-width: 767px) {
  .footer .inner .logo-cont .pic {
    max-width: 230px;
  }
}
.footer .inner .logo-cont .fukidashi {
  width: 68px;
}
@media only screen and (max-width: 767px) {
  .footer .inner .logo-cont .fukidashi {
    width: 57px;
  }
}
.footer .inner .text-cont {
  margin: 32px auto 0;
  width: 100%;
  max-width: 800px;
}
@media only screen and (max-width: 767px) {
  .footer .inner .text-cont {
    margin-top: 24px;
    padding: 0 10px;
  }
}
.footer .inner .text-cont p {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.6em;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .footer .inner .text-cont p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5em;
    text-align: justify;
  }
}
.footer .inner .sns-cont {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
}
@media only screen and (max-width: 767px) {
  .footer .inner .sns-cont {
    margin-top: 30px;
    gap: 14px;
  }
}
.footer .inner .sns-cont .share {
  width: 174px;
}
@media only screen and (max-width: 767px) {
  .footer .inner .sns-cont .share {
    width: 120px;
  }
}
.footer .inner .link-cont {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 0.28em;
  line-height: 1em;
  font-weight: 500;
  gap: 1em;
}
@media screen and (max-width: 1100px) {
  .footer .inner .link-cont {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 900px) {
  .footer .inner .link-cont {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 767px) {
  .footer .inner .link-cont {
    display: none;
  }
}
.footer .inner .copy {
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .footer .inner .copy {
    font-size: 10px;
    font-size: 1rem;
    margin-top: 24px;
  }
}
.footer .inner .info-link-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 33px;
}
@media only screen and (max-width: 767px) {
  .footer .inner .info-link-cont {
    margin-top: 22px;
  }
}
.footer .inner .info-link-cont .list {
  border-top: 1px solid #000000;
  padding-top: 36px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .footer .inner .info-link-cont .list {
    padding-top: 18px;
    max-width: 325px;
  }
}
.footer .inner .info-link-cont .list li {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.18em;
  padding: 2px 16px;
  border-right: 1px solid #000000;
}
@media only screen and (max-width: 767px) {
  .footer .inner .info-link-cont .list li {
    font-size: 11px;
    font-size: 1.1rem;
    letter-spacing: 0em;
    padding: 1px 10px;
    text-align: center;
  }
}
.footer .inner .info-link-cont .list li:first-child {
  padding-left: 0;
}
.footer .inner .info-link-cont .list li:last-child {
  padding-right: 0;
  border-right: none;
}

.header {
  height: 160px;
  padding: 0px 52px;
  width: 100%;
  background-color: white;
  z-index: 100;
}
@media only screen and (max-width: 767px) {
  .header {
    height: 52px;
    padding: 0 16px;
  }
}
.header .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .header .inner:before {
    content: "";
    width: 54px;
    height: 0px;
  }
}
.header .inner .pen {
  width: 350px;
}
@media only screen and (max-width: 767px) {
  .header .inner .pen {
    display: none;
  }
}
.header .inner .logo {
  width: 450px;
}
@media only screen and (max-width: 767px) {
  .header .inner .logo {
    width: 180px;
  }
}
.header .inner .utility {
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}
@media only screen and (max-width: 767px) {
  .header .inner .utility {
    gap: 12px;
    width: 54px;
  }
}
.header .inner .utility .scope {
  width: 36px;
}
@media only screen and (max-width: 767px) {
  .header .inner .utility .scope {
    width: 21px;
  }
}
.header .inner .utility .menu {
  width: 35px;
}
@media only screen and (max-width: 767px) {
  .header .inner .utility .menu {
    width: 21px;
  }
}

.modal {
  width: 100%;
  height: 100vh;
  background-image: url(../images/common/modal-back.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
}
@media only screen and (max-width: 767px) {
  .modal {
    background-size: 120% 150%;
  }
}
.modal .modal-wrap {
  position: relative;
}
.modal .modal-wrap .inner {
  max-width: 860px;
  padding: 100px 0 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner {
    padding: 80px 0 80px;
    width: calc(100% - 80px);
  }
}
.modal .modal-wrap .inner .close {
  position: absolute;
  top: 47px;
  right: 50px;
  width: 52px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .close {
    width: 30px;
    top: 24px;
    right: 24px;
  }
}
.modal .modal-wrap .inner .menu-logo {
  width: 381px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .menu-logo {
    width: 250px;
  }
}
.modal .modal-wrap .inner .only-menu {
  padding-top: 85px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu {
    padding-top: 38px;
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 64px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont {
    gap: 15px;
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  max-width: 398px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont .item {
    gap: 16px;
    max-width: 100%;
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont .item .pic {
  width: 59px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont .item .pic {
    width: 40px;
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont .item .pic img.book {
  width: 59px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont .item .pic img.book {
    width: 40px;
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont .item .pic img.speaker {
  width: 50px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont .item .pic img.speaker {
    width: 34px;
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont .item .pic img.hash {
  width: 55px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont .item .pic img.hash {
    width: 37px;
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont .item .pic img.talk {
  width: 49px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont .item .pic img.talk {
    width: 33px;
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont .item .text {
  color: white;
  font-size: 21px;
  font-size: 2.1rem;
  letter-spacing: 0.2em;
  line-height: 1.5em;
  border-bottom: 1px solid white;
  height: 52px;
  width: calc(100% - 59px - 24px);
  padding-right: 37px;
  white-space: nowrap;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont .item .text {
    font-size: 14px;
    font-size: 1.4rem;
    height: 36px;
    width: calc(100% - 40px - 16px);
  }
}
.modal .modal-wrap .inner .only-menu .menu-cont .item .text:after {
  content: "";
  width: 37px;
  height: 13px;
  background-image: url(../images/common/arr2_wh.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .menu-cont .item .text:after {
    width: 25px;
    height: 9px;
    top: 7px;
    bottom: auto;
  }
}
.modal .modal-wrap .inner .only-menu .info-link-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 76px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .info-link-cont {
    margin-top: 30px;
    padding-left: 56px;
    justify-content: flex-start;
  }
}
.modal .modal-wrap .inner .only-menu .info-link-cont .list {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .info-link-cont .list {
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 260px;
    gap: 18px 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
.modal .modal-wrap .inner .only-menu .info-link-cont .list li {
  font-size: 17px;
  font-size: 1.7rem;
  letter-spacing: 0.22em;
  color: white;
  padding: 2px 20px;
  border-right: 1px solid white;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-menu .info-link-cont .list li {
    font-size: 12px;
    font-size: 1.2rem;
    padding: 1px 0;
    border-right: none;
  }
}
.modal .modal-wrap .inner .only-menu .info-link-cont .list li:first-child {
  padding-left: 0;
}
.modal .modal-wrap .inner .only-menu .info-link-cont .list li:last-child {
  padding-right: 0;
  border-right: none;
}
.modal .modal-wrap .inner .only-search {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-search {
    margin-top: 40px;
  }
}
.modal .modal-wrap .inner .only-search .search-form {
  position: relative;
  border: 1px solid white;
  height: 70px;
  width: 100%;
  max-width: 656px;
  display: flex;
  align-items: center;
  padding-left: 70px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-search .search-form {
    height: 48px;
    padding-left: 50px;
  }
}
.modal .modal-wrap .inner .only-search .search-form:before {
  content: "";
  width: 35px;
  height: 35px;
  background-image: url(../images/common/scope_wh.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-search .search-form:before {
    width: 24px;
    height: 24px;
    left: 16px;
  }
}
.modal .modal-wrap .inner .only-search .search-form input[type=text] {
  border: none;
  position: relative;
  height: 2em;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  font-size: 22px;
  font-size: 2.2rem;
  color: white;
  letter-spacing: 0.15em;
  caret-color: white;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-search .search-form input[type=text] {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
.modal .modal-wrap .inner .only-search .search-form input[type=text]::placeholder {
  color: white;
}
.modal .modal-wrap .inner .only-search .search-form input[type=text]:focus {
  outline: none;
}
.modal .modal-wrap .inner .only-search .search-form input[type=text]:-webkit-autofill, .modal .modal-wrap .inner .only-search .search-form input[type=text]:-webkit-autofill:hover, .modal .modal-wrap .inner .only-search .search-form input[type=text]:-webkit-autofill:focus {
  font-size: 22px !important;
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}
.modal .modal-wrap .inner .only-search .search-form button {
  cursor: pointer;
  border: none;
  outline: none;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
}
@media only screen and (max-width: 767px) {
  .modal .modal-wrap .inner .only-search .search-form button {
    width: 48px;
    height: 48px;
  }
}
.modal .modal-wrap .inner .only-search .search-form button img {
  width: 36px;
}

#pickup {
  margin-bottom: 80px;
}
@media only screen and (max-width: 767px) {
  #pickup {
    margin-bottom: 44px;
  }
}
#pickup .inner .pickup-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-title {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
}
@media only screen and (min-width: 768px) {
  #pickup .inner .pickup-title:before {
    content: "";
    width: 33.333%;
    height: 1px;
    display: block;
    background-color: #C277AD;
  }
}
#pickup .inner .pickup-title .pic {
  width: 100%;
  max-width: 160px;
  padding-top: 30px;
}
@media screen and (max-width: 1000px) {
  #pickup .inner .pickup-title .pic {
    max-width: 180px;
  }
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-title .pic {
    width: 120px;
    padding-top: 10px;
  }
}
#pickup .inner .pickup-title .words {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 0.3em;
  font-weight: 500;
  line-height: 1.75em;
  width: 33.333%;
  white-space: nowrap;
}
@media screen and (max-width: 1000px) {
  #pickup .inner .pickup-title .words {
    font-size: 24px;
    font-size: 2.4rem;
  }
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-title .words {
    font-size: 18px;
    font-size: 1.8rem;
    letter-spacing: 0.3em;
    width: 100%;
    text-align: center;
  }
}
#pickup .inner .pickup-list {
  display: flex;
  flex-direction: column;
  margin-top: 54px;
}
@media only screen and (min-width: 768px) {
  #pickup .inner .pickup-list {
    width: 100%;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-list {
    margin-top: 24px;
  }
}
#pickup .inner .pickup-list .pickup-item {
  width: 100%;
  padding-bottom: 28px;
  border-bottom: 1px solid gray;
  margin-bottom: 28px;
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-list .pickup-item {
    padding-bottom: 22px;
    margin-bottom: 14px;
  }
}
#pickup .inner .pickup-list .pickup-item:nth-child(1) {
  border-bottom: 1px solid #AACC03;
}
#pickup .inner .pickup-list .pickup-item:nth-child(2) {
  border-bottom: 1px solid #7F1083;
}
#pickup .inner .pickup-list .pickup-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
#pickup .inner .pickup-list .pickup-item a {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 0 42px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-list .pickup-item a {
    padding-bottom: 16px;
  }
}
#pickup .inner .pickup-list .pickup-item a:after {
  content: "";
  width: 46px;
  height: 10px;
  background-image: url(../images/common/arr1.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-list .pickup-item a:after {
    width: 30px;
    height: 7px;
  }
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-list .pickup-item a .pic-cont {
    display: none;
  }
}
#pickup .inner .pickup-list .pickup-item a .pic-cont .pic {
  transition: 0.3s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
#pickup .inner .pickup-list .pickup-item a .text {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 0.18em;
  line-height: 1.8em;
  padding-right: 50px;
}
@media only screen and (max-width: 767px) {
  #pickup .inner .pickup-list .pickup-item a .text {
    font-size: 16px;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    line-height: 1.7em;
    padding-right: 0;
  }
}

.c-color-base {
  color: #000000 !important;
}

.c-color-beige {
  color: #FAF7F1 !important;
}

.c-color-purple {
  color: #7F1083 !important;
}

.c-color-red {
  color: #C30D23 !important;
}

.c-color-darkpink {
  color: #E51373 !important;
}

.c-color-pink {
  color: #EA6DA4 !important;
}

.c-color-orange {
  color: #F29600 !important;
}

.c-color-lime {
  color: #AACC03 !important;
}

.c-color-green {
  color: #009844 !important;
}

.c-color-sky {
  color: #00A0E9 !important;
}

.c-color-blue {
  color: #0068B6 !important;
}

.c-bottom-color-base {
  border-bottom: 1px solid #000000 !important;
}

.c-bottom-color-beige {
  border-bottom: 1px solid #FAF7F1 !important;
}

.c-bottom-color-purple {
  border-bottom: 1px solid #7F1083 !important;
}

.c-bottom-color-red {
  border-bottom: 1px solid #C30D23 !important;
}

.c-bottom-color-darkpink {
  border-bottom: 1px solid #E51373 !important;
}

.c-bottom-color-pink {
  border-bottom: 1px solid #EA6DA4 !important;
}

.c-bottom-color-orange {
  border-bottom: 1px solid #F29600 !important;
}

.c-bottom-color-lime {
  border-bottom: 1px solid #AACC03 !important;
}

.c-bottom-color-green {
  border-bottom: 1px solid #009844 !important;
}

.c-bottom-color-sky {
  border-bottom: 1px solid #00A0E9 !important;
}

.c-bottom-color-blue {
  border-bottom: 1px solid #0068B6 !important;
}

.c-edge {
  position: relative;
  max-width: 488px;
  width: 100%;
  aspect-ratio: 488/320;
  margin: 0 auto;
}
.c-edge:before, .c-edge:after {
  content: "";
  position: absolute;
  width: 85px;
  height: 1px;
  background-color: black;
  z-index: 1;
  transform: rotate(-45deg);
}
@media only screen and (max-width: 767px) {
  .c-edge:before, .c-edge:after {
    width: 60px;
  }
}
.c-edge:before {
  top: 19px;
  left: -24px;
}
@media only screen and (max-width: 767px) {
  .c-edge:before {
    left: -11px;
  }
}
.c-edge:after {
  bottom: 19px;
  right: -24px;
}
@media only screen and (max-width: 767px) {
  .c-edge:after {
    right: -11px;
  }
}
.c-edge figure, .c-edge img, .c-edge div {
  clip-path: polygon(38px 0%, 100% 0%, 100% calc(100% - 38px), calc(100% - 38px) 100%, 0% 100%, 0% 38px);
}

.c-edge-mini {
  position: relative;
  width: 100%;
  max-width: 200px;
  aspect-ratio: 200/129;
}
@media only screen and (max-width: 767px) {
  .c-edge-mini {
    margin: 0 auto;
  }
}
.c-edge-mini:before, .c-edge-mini:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 1px;
  background-color: black;
  z-index: 1;
  transform: rotate(-45deg);
}
.c-edge-mini:before {
  top: 10px;
  left: -10px;
}
.c-edge-mini:after {
  bottom: 10px;
  right: -10px;
}
.c-edge-mini figure, .c-edge-mini img, .c-edge-mini div {
  clip-path: polygon(20px 0%, 100% 0%, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0% 100%, 0% 20px);
}

.c-rainbow {
  border: none;
  height: 3px;
  background: linear-gradient(to right, #F29600, #AACC03, #009844, #00A0E9, #0068B6, #7F1083, #C30D23, #E51373, #EA6DA4);
  border-radius: 999px; /* 丸みでなめらかに */
  margin: 2em 0;
}
@media only screen and (max-width: 767px) {
  .c-rainbow {
    height: 2px;
  }
}

.c-article-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  gap: 58px 8.75%;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .c-article-list {
    gap: 40px 0;
    flex-direction: column;
  }
}
.c-article-list .item {
  width: 45.437%;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item {
    width: 100%;
  }
}
.c-article-list .item .upper .pic {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.c-article-list .item .upper .info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-top: 33px;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .upper .info {
    margin-top: 18px;
  }
}
.c-article-list .item .upper .info:before {
  content: "";
  width: 44px;
  height: 1px;
  display: block;
  background-color: #000000;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .upper .info:before {
    width: 22px;
    margin-right: 8px;
  }
}
.c-article-list .item .upper .info time {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-size: 17px;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  margin-right: 0.8em;
  padding-top: 5px;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .upper .info time {
    font-size: 13px;
    font-size: 1.3rem;
    padding-top: 2px;
  }
}
.c-article-list .item .upper .info time:after {
  content: "";
  width: 1px;
  height: 20px;
  display: block;
  margin-left: 0.8em;
  background-color: #000000;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .upper .info time:after {
    height: 14px;
  }
}
.c-article-list .item .upper .info .cat {
  font-size: 17px;
  font-size: 1.7rem;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  line-height: 1em;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .upper .info .cat {
    font-size: 13px;
    font-size: 1.3rem;
    letter-spacing: 2px;
  }
}
.c-article-list .item .upper .title {
  margin-top: 12px;
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 0.1em;
  line-height: 1.81818em;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .upper .title {
    font-size: 16px;
    font-size: 1.6rem;
    margin-top: 7px;
  }
}
.c-article-list .item .upper .person {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 0.2em;
  line-height: 2.1em;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .upper .person {
    font-size: 13px;
    font-size: 1.3rem;
  }
}
.c-article-list .item .tag-list {
  border-top: 1px dashed #000000;
  margin-top: 15px;
  padding-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 1em 0.5em;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .tag-list {
    margin-top: 10px;
    padding-top: 16px;
  }
}
.c-article-list .item .tag-list .tag {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  line-height: 1em;
}
@media only screen and (max-width: 767px) {
  .c-article-list .item .tag-list .tag {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 0.6em;
  }
}
.c-article-list .item:after {
  content: "";
  width: 1px;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  right: -10%;
  background-color: gray;
}
@media only screen and (min-width: 768px) {
  .c-article-list .item:nth-child(odd):after {
    display: block;
  }
}
.c-article-list .item:nth-child(1):after {
  background-color: #0068B6;
}
.c-article-list .item:nth-child(3):after {
  background-color: #EA6DA4;
}
.c-article-list .item:nth-child(5):after {
  background-color: #009844;
}
.c-article-list .item:nth-child(7):after {
  background-color: #7F1083;
}
.c-article-list .item:nth-child(9):after {
  background-color: #F29600;
}

.c-color-tag:before {
  content: "#";
  margin-right: 3px;
  color: gray;
}
.c-color-tag.purple:before {
  color: #7F1083;
}
.c-color-tag.red:before {
  color: #C30D23;
}
.c-color-tag.darkpink:before {
  color: #E51373;
}
.c-color-tag.pink:before {
  color: #EA6DA4;
}
.c-color-tag.orange:before {
  color: #F29600;
}
.c-color-tag.lime:before {
  color: #AACC03;
}
.c-color-tag.green:before {
  color: #009844;
}
.c-color-tag.sky:before {
  color: #00A0E9;
}
.c-color-tag.blue:before {
  color: #0068B6;
}

.c-read-from-tag {
  background-color: #FAF7F1;
  border-radius: 28px;
  padding: 50px 53px;
}
@media only screen and (max-width: 767px) {
  .c-read-from-tag {
    width: calc(100% + 50px);
    margin-left: -25px;
    border-radius: 0;
    position: relative;
    padding: 24px 24px 24px;
  }
  .c-read-from-tag:before {
    content: "";
    width: 100%;
    height: 20px;
    background-image: url(../images/common/book_top.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 20px;
    display: block;
    position: absolute;
    top: -19px;
    left: 0;
    margin: auto;
  }
  .c-read-from-tag:after {
    content: "";
    width: 100%;
    height: 31px;
    background-image: url(../images/common/book_bottom.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 31px;
    display: block;
    position: absolute;
    bottom: -30px;
    left: 0;
    margin: auto;
  }
}
@media only screen and (min-width: 768px) {
  .c-read-from-tag.wh {
    background-color: white;
  }
}
.c-read-from-tag .tag-title {
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 0.3em;
  font-weight: 500;
  line-height: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  margin-left: -53px;
}
@media only screen and (max-width: 767px) {
  .c-read-from-tag .tag-title {
    font-size: 19px;
    font-size: 1.9rem;
    letter-spacing: 0.2em;
    margin-left: -24px;
  }
}
.c-read-from-tag .tag-title:before {
  content: "";
  width: 151px;
  height: 1px;
  display: block;
  background-color: #00A0E9;
}
@media only screen and (max-width: 767px) {
  .c-read-from-tag .tag-title:before {
    width: 50px;
  }
}
.c-read-from-tag .tag-list {
  margin-top: 15px;
  padding-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 1em 1.2em;
}
@media only screen and (max-width: 767px) {
  .c-read-from-tag .tag-list {
    margin-top: 10px;
    padding-top: 16px;
    gap: 0.55em 0.8em;
  }
}
.c-read-from-tag .tag-list .tag {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.3em;
}
@media only screen and (max-width: 767px) {
  .c-read-from-tag .tag-list .tag {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-breadcrumbs ul {
  display: flex;
  align-items: center;
  gap: 12px;
}
@media only screen and (max-width: 767px) {
  .c-breadcrumbs ul {
    gap: 6px;
  }
}
.c-breadcrumbs ul li, .c-breadcrumbs ul a {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.22em;
}
@media only screen and (max-width: 1032px) {
  .c-breadcrumbs ul li, .c-breadcrumbs ul a {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 767px) {
  .c-breadcrumbs ul li, .c-breadcrumbs ul a {
    font-size: 10px;
    font-size: 1rem;
    font-feature-settings: "palt";
  }
}
.c-breadcrumbs ul li {
  display: flex;
  align-items: center;
  gap: 12px;
}
@media only screen and (max-width: 767px) {
  .c-breadcrumbs ul li {
    gap: 6px;
  }
}
.c-breadcrumbs ul li:not(:first-child):before {
  content: "＞";
  letter-spacing: 0;
}

.c-sns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
@media only screen and (max-width: 767px) {
  .c-sns {
    gap: 6px;
  }
}
.c-sns a {
  display: block;
  width: 52px;
}
@media only screen and (max-width: 767px) {
  .c-sns a {
    width: 34px;
  }
}
.c-sns a img {
  width: 100%;
}

.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
@media only screen and (max-width: 767px) {
  .wp-pagenavi {
    gap: 13px;
  }
}
.wp-pagenavi span,
.wp-pagenavi a {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 700;
  transition: 0.3s ease;
}
@media only screen and (min-width: 768px) {
  .wp-pagenavi a:hover {
    color: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
.wp-pagenavi .current {
  color: rgba(0, 0, 0, 0.5);
}
.wp-pagenavi .previouspostslink,
.wp-pagenavi .nextpostslink {
  width: 36px;
  aspect-ratio: 36/14;
  background: url(../images/common/arr2.svg) no-repeat center/contain;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}
@media only screen and (min-width: 768px) {
  .wp-pagenavi .previouspostslink:hover,
  .wp-pagenavi .nextpostslink:hover {
    opacity: 1;
  }
}
@media only screen and (max-width: 767px) {
  .wp-pagenavi .previouspostslink,
  .wp-pagenavi .nextpostslink {
    width: 24px;
  }
}
.wp-pagenavi .previouspostslink {
  margin-right: 8px;
  transform: rotate(180deg);
}
@media only screen and (min-width: 768px) {
  .wp-pagenavi .previouspostslink:hover {
    transform: rotate(180deg) translateX(10px);
  }
}
@media only screen and (max-width: 767px) {
  .wp-pagenavi .previouspostslink {
    margin-right: 5px;
  }
}
.wp-pagenavi .nextpostslink {
  margin-left: 8px;
}
@media only screen and (min-width: 768px) {
  .wp-pagenavi .nextpostslink:hover {
    transform: translateX(10px);
  }
}
@media only screen and (max-width: 767px) {
  .wp-pagenavi .nextpostslink {
    margin-left: 5px;
  }
}